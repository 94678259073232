import {Grid, Popover} from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import "./VideoList.css"
import { Auth } from "aws-amplify";
import axios from "../../config/axios";

function VideoList({video , handleClickVideo , handleClickMaker , auth , page}){
    console.log("🚀 ~ file: VideoList.js:12 ~ VideoList ~ video", video)
    const [liked , setLiked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(()=>{
      if(video.S){
        setLiked(true)
      }else{
        setLiked(false)
      }
    },[video])

    const open = Boolean(anchorEl);
    var id;
    const addFavorite = async(e,data) => {
      e.stopPropagation()
      if(auth){
        const user = await Auth.currentAuthenticatedUser()
        axios.get(`add-favorite?videoId=${data}&userId=${user.username}`)
        setLiked(!liked)
        id = 'simple-popper';
        setAnchorEl(null)
      }else{
        id = undefined;
        setAnchorEl('')
      }
    }

    const login = ()=> {
      Auth.federatedSignIn({ provider : "Google"}).then(user => {axios.get(`user_login?userId=${user.username}`)})
    }

    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePreSign = async(data) =>{
      const res =  await axios.get(`pre-sign-url?logo=${data}`);
      if(res.status === 200){
        console.log("🚀 ~ file: VideoList.js:55 ~ preSign ~ res.data.data", res.data.data)
        return res.data.data;
      }
    }

    if((page === 'favorite' && video.S )|| page === 'home'){
    return (
    <Grid key={video.id} item xs={12} sm={6} md={4} lg={3}>
        <div className="list-video">
          <div
            className="list-video__video"
            
          >
            <div
              className="list-video__video-iframe"
              style={{
                backgroundColor: "#ccc",
                backgroundImage: `url(${
                    video.meta.outdoor
                      ? video.meta.outdoor
                      : ""
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <PlayCircleFilledWhiteIcon className="list-video__play-icon" onClick={() => handleClickVideo(video.id)}/>
              <div className="div-star">
                {liked?<img 
                src="/svg/star.svg" 
                className="img-star" 
                onClick={(e)=> {addFavorite(e,video.id)}}/>:
                <img 
                src="/svg/star-o.svg" 
                className="img-star" 
                onClick={auth?(e)=> {addFavorite(e,video.id)}:handleClick} 
                aria-describedby={id}/>}
                <Popover 
                id={id} 
                open={open} 
                anchorEl={anchorEl} 
                onClose={handleClose} 
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                >
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }} className="box-login">
                    <p>お気に入りするには、</p>
                    <p>ログインしてください</p>
                    <div onClick={login}><u>ログイン</u></div>
                  </Box>
                </Popover>
              </div>
            </div>
          </div>
          <div className="list-video__title">
            <Avatar
              onClick={() => handleClickMaker(video.makerID)}
              sizes="big"
              className="list-video__title-avatar"
              alt="avatar"
              sx={{ width: 46, height: 46 }}
              src={video.logo}
            ></Avatar>
            <div
              className="list-video__title-text"
              onClick={() => handleClickVideo(video.id)}
            >
              <div className="title__main">{video.title}</div>
              <div>{video.com_name}</div>
            </div>
          </div>
          <div className="title__sub">
            {format(new Date(Date.parse(video.createdAt)), "yyyy/MM/dd")}
          </div>
        </div>
      </Grid>
    )
  }else{
    return <></>
  }
}
export default VideoList