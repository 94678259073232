import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./Editor.css";

const MyCkeditor = ({ value, parentCallback, isError }) => {
  return (
    <div>
      <div className={isError === true ? "error-editor" : ""}>
        <CKEditor
          editor={Editor}
          data={value}
          onReady={(editor) => {}}
          onChange={(event, editor) => {
            const data = editor.getData();
            parentCallback(data);
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </div>
    </div>
  );
};

export default MyCkeditor;
