/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid , RadioGroup , Radio, FormControlLabel } from "@mui/material";
import TagList from "../../Components/TagList/TagList";
import { useEffect, useState } from "react";
import axios from "../../config/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./VideoDetail.css";

function VideoDetail() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState([]);
  const [video, setVideo] = useState([]);
  const [tags, setTags] = useState([]);
  const [houseData, setHouseData] = useState("");
  const [showHouseData, setShowHouseData] = useState(false);
  const [des , setDes] = useState("");
  useEffect(() => {
    axios.get(`video/${params.get("v")}`).then((res) => {
      setVideo(res.data.data);
      setTags(res.data.data.tags);
      setHouseData(res.data.data.meta.house_data);
      res.data.data.description?setDes(convertDataToHtml(JSON.parse(res.data.data.description))):setDes("")
      axios.get(`companie/show/${res.data.data.makerID}`).then((response) => {
        console.log("🚀 ~ file: VideoDetail.js ~ line 25 ~ axios.get ~ response", response)
        setCompany(response.data.data);
      });
    });
    window.scroll(0, 0);
  }, [params.get("v")]);

  const handleClickWatchCompany = () => {
    navigate(`/about/${company.makerID}`);
  };

  const onChangeRadio =()=>{
    setShowHouseData(!showHouseData)
  }

  const convertDataToHtml = (blocks) => {
    var convertedHtml = "";
    blocks.blocks.map(block => {
      
      switch (block.type) {
        case "header":
          convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
          break;
        case "embded":
          convertedHtml += `<div><iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
          break;
        case "paragraph":
          convertedHtml += `<p>${block.data.text}</p>`;
          break;
        case "delimiter":
          convertedHtml += "<hr />";
          break;
        case "image":
          convertedHtml += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
          break;
        case "list":
          convertedHtml += "<ul>";
          block.data.items.forEach(function(li) {
            convertedHtml += `<li>${li}</li>`;
          });
          convertedHtml += "</ul>";
          break;
        default:
          console.log("Unknown block type", block.type);
          break;
      }
    });
    return convertedHtml;
  };





  return (
    <div className="video-detail">
      <div className="video-title">{video.title}</div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <div className="">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              onChange={onChangeRadio}
            >
              <FormControlLabel
                value="hide"
                control={<Radio />}
                label="デフォルト"
                checked={!showHouseData}
              />
              <FormControlLabel
                value="show"
                control={<Radio />}
                label="カスタム"
              />
            </RadioGroup>
          </div> */}
          {houseData&& 
            (
            <>
            <div className="  video-detail__company-name">詳細</div>
            <div style={{ whiteSpace: "pre-line" }}>{houseData}</div>
            </>
            )
          }
          
          <div className="video-detail__video">
            <iframe
              title="iframe"
              className="video-detail__iframe"
              src={`https://www.v2.apotto.jp/space/store/${params.get("v")}?&lid=${params.get("v")}`}
            ></iframe>
          </div>
          {des&& 
            <>
              <Divider className="video-detail__divider" />
              <div className="video-detail__tag-list">
              
                <div className="video-desctipton" dangerouslySetInnerHTML={{ __html: des }}>
                </div>
                
              </div>
            </>
          }
          {
            tags&&<>
              <Divider className="video-detail__divider" />
              <div className="video-detail__tag-list">
              <TagList
                  isEdit={false}
                  tagList={tags ? [{ tag: tags }] : '[]'}
                  parentCallback={() => {}}
                />
              </div>
            </>
          }
            
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <div className="video-detail__company">
            
            <Divider className="video-detail__divider" />
            <div className="video-detail__company-name">{company.com_name}</div>
            <Grid container spacing={2}>
              <Grid className="video-detail__company-info" item xs={3} md={1}>
                電話番号
              </Grid>
              <Grid item>
                :
              </Grid>
              <Grid item xs={8} md={10}>
                <div className="video-detail__company-info">
                  {company.com_phone}
                </div>
              </Grid>
              <Grid className="video-detail__company-info" item xs={3} md={1}>
                住所
              </Grid>
              <Grid item>
                :
              </Grid>
              <Grid item xs={8} md={10}>
                <div className="video-detail__company-info">
                  {company.address}
                </div>
              </Grid>
              <Grid className="video-detail__company-info" item xs={3} md={1}>
                ホームページ
              </Grid>
              <Grid item>
                :
              </Grid>
              <Grid item xs={8} md={10}>
                <div className="video-detail__company-info">
                  {company.meta
                    ? company.meta.home_page
                      ? company.meta.home_page
                      : ""
                    : ""}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="see-company" onClick={handleClickWatchCompany}>
            会社詳細を見る
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default VideoDetail;
