import MyCkeditor from "../../../Components/Editor/Editor";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import React, { useEffect } from "react";
import { Storage, Amplify } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { v4 as uuidv4 } from "uuid";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import "./CreateTrendy.css";
import axios from "../../../config/axios";
import InputTags from "../../../Components/InputTags/InputTags";
import toast, { Toaster } from "react-hot-toast";

function CreateTrendy() {
  const [openLoading, setOpenLoading] = useState(false);
  Amplify.configure({
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:bbe4b373-416f-4bb4-951e-63b5bde2b2d6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Ga0inlK7E",
    "aws_user_pools_web_client_id": "3lgf1laq1qmortv99g7gv4nsmu",
    "oauth": {
        "domain": "apottoxinc752db7cf-752db7cf-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "apottoxincauth://",
        "redirectSignOut": "apottoxincauth://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],    
    "aws_user_files_s3_bucket": "apottoxincdevs3bucket155457-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
  });
  const [blog, setBlog] = useState({
    id: "",
    title: "",
    status: "public",
    description: "",
    url: "",
    updatedAt: new Date(),
  });
  var [description, setDescription] = useState("");
  var [tags, setTags] = useState([]);
  const [isValidTitle, setIsValidTitle] = useState(true);
  const [isValidDescription, setIsValidDescription] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();

  var getBase64Image = function (url, callback) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("CANVAS");
      var ctx = canvas.getContext("2d");
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  };
  useEffect(() => {
    axios.get(`/admin/blog/${id}`).then((res) => {
      if (res.data.data.id !== undefined) {
        setBlog(res.data.data);
        setTags(res.data.data.tags);
        const regexImage = /<img[^>]+src="([^">]+)"/g;
        const urls = res.data.data.description.match(regexImage);
        if (urls !== null) {
          for (let i = 0; i < urls.length; i++) {
            const url = regexImage.exec(urls[i]);
            regexImage.lastIndex = 0;
            Storage.get(url[1]).then((imageUrl) => {
              getBase64Image(imageUrl, function (base64) {
                res.data.data.description = res.data.data.description.replace(
                  url[1],
                  base64
                );
                setDescription(res.data.data.description);
              });
            });
          }
        }
        setDescription(res.data.data.description);
      }
    });
  }, [id]);

  const handleChangeInput = (event) => {
    setBlog({ ...blog, [event.target.name]: event.target.value });
  };

  const handleChangeDescription = (value) => {
    setDescription(value);
  };

  const handleSave = async () => {
    if (blog.title.trim() === "") {
      setIsValidTitle(false);
      return;
    } else {
      setIsValidTitle(true);
    }
    if (description.trim() === "") {
      setIsValidDescription(false);
      return;
    } else {
      setIsValidTitle(true);
    }
    setOpenLoading(true);
    if (id === undefined) {
      id = uuidv4();
    }
    const regexImage = /src="(data:image\/[^;]+;base64,([^"]+))"/g;
    const urls = description.match(regexImage);
    if (urls !== null) {
      for (let i = 0; i < urls.length; i++) {
        const url = regexImage.exec(urls[i]);
        regexImage.lastIndex = 0;
        const byteCharacters = window.atob(url[2]);
        let len = byteCharacters.length;
        const u8arr = new Uint8Array(len);
        while (len--) {
          u8arr[len] = byteCharacters.charCodeAt(len);
        }
        var image = new File([u8arr], `image${i}.jpg`, {
          type: "image/jpeg",
        });

        const makerID = localStorage.getItem("@makerID");
        const fileName = `${makerID}/draftEvent/${id}/image${i}.jpg`;
        await Storage.put(fileName, image);
        description = description.replace(url[1], fileName);
        setDescription(description);
      }
    }
    if (blog.id !== "") {
      axios
        .put(`/admin/update-blog/${blog.id}`, {
          title: blog.title.trim(),
          url: blog.url.trim()===''?blog.id:blog.url.trim(),
          tags: tags.toString(),
          status: blog.status.trim(),
          description: description.trim(),
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast("Edit blog successfully");
            setOpenLoading(false);
            navigate("/admin/blogs");
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            console.log(error.response.data.errors);
          }
        });
    } else {
      axios
        .post(`/admin/create-blog`, {
          id: id,
          title: blog.title.trim(),
          url: blog.url.trim(),
          tags: tags.toString(),
          status: blog.status.trim(),
          description: description.trim(),
        })
        .then((res) => {
          if (res.status === 200) {
            setOpenLoading(false);
            toast("Create blog successfully");
            navigate("/admin/blogs");
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            console.log(error.response.data.errors);
          }
        });
    }
  };

  const getTagList = (data) => {
    if (typeof data[0] === "object") {
      data[0].push(data[1]);
      setTags(data[0]);
    } else {
      setTags(data);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };


  return (
    <div className="create-trendy">
      <div onClick={handleBack} className="back-btn">
        <span className="back-btn__text">
          <svg
            width="7"
            height="17"
            viewBox="0 0 7 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.31303 0.0853767L0 8.07928L5.31303 16.0732L6.2561 14.6543L1.88613 8.07928L6.2561 1.50429L5.31303 0.0853767Z"
              fill="black"
            />
          </svg>
          <span className="back-btn__content">戻る</span>
        </span>
      </div>
      <div className="form-create">
        <label>タイトル名</label>
        <TextField
          error={!isValidTitle}
          helperText={!isValidTitle ? "*必須項目" : ""}
          className="form-create__text-field"
          name="title"
          size="small"
          value={blog.title}
          onChange={handleChangeInput}
          sx={{ width: "100%" }}
          id="outlined-basic"
          variant="outlined"
        />
        <label className="font-weight">URL</label>
        <div className="divUrl">
          <span className="spanUrl">https://portal.apotto.jp/detail/</span>
          <TextField
            error={!isValidTitle}
            helperText={!isValidTitle ? "*必須項目" : ""}
            className="form-create__text-field textUrl"
            name="url"
            size="small"
            value={blog.url?blog.url:blog.id}
            onChange={handleChangeInput}
            sx={{ width: "100%" }}
            id="outlined-basic"
            variant="outlined"
          />
        </div>
        <label>タグ</label>
        <InputTags parentCallback={getTagList} tagList={blog.tags} />
        <label className="form__label">
          <FormControl>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="form__radio-label"
            >
              状況
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={blog.status}
              onChange={handleChangeInput}
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="公開"
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="非公開"
              />
            </RadioGroup>
          </FormControl>
        </label>
        <div className="form__label">
          <div>本文</div>
          <MyCkeditor
            name="description"
            value={description}
            parentCallback={handleChangeDescription}
            isError={!isValidDescription}
          />
          {!isValidDescription ? (
            <div className="error-message">*必須項目</div>
          ) : (
            ""
          )}
        </div>
        <Button className="save-btn" variant="contained" onClick={handleSave}>
          保存
        </Button>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Toaster
        toastOptions={{
          style: {
            borderBottom: "3px solid green",
          },
        }}
      />
    </div>
  );
}

export default CreateTrendy;
