import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";
import "./TableData.css";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";

export default function TableData({ dataType, columns, rows }) {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleClickRow = (row) => {
    navigate(`/detail/${row.id}`);
  };

  const onClickEditBlog = (id) => {
    navigate(`/admin/edit/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    jaJP
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((row, index) => (
              <TableCell
                key={index}
                className="table-header table-header-no"
                align="center"
              >
                {row}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .filter(
              (row, index) =>
                index >= (page + 1) * rowsPerPage - rowsPerPage &&
                index < (page + 1) * rowsPerPage
            )
            // eslint-disable-next-line array-callback-return
            .map((row, index) => {
              if (dataType === "blog") {
                return (
                  <TableRow hover={true} key={index}>
                    <TableCell align="center">
                      {rows.indexOf(row) + 1}
                    </TableCell>
                    <TableCell align="center" style={{ cursor: "pointer" }}>
                      <Link
                        className="table-blog-title"
                        to={`/detail/${row.id}`}
                        target={"_blank"}
                      >
                        {row.title}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {format(
                        new Date(Date.parse(row.updatedAt)),
                        "yyyy/MM/dd"
                      )}
                    </TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickEditBlog(row.id)}
                    >
                      <BorderColorRoundedIcon
                        fontSize="small"
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                );
              } else if (dataType === "company") {
                return (
                  <TableRow hover={true} key={index}>
                    <TableCell align="center">
                      {rows.indexOf(row) + 1}
                    </TableCell>
                    <TableCell align="center">{row.com_name}</TableCell>
                    <TableCell align="center">{row.com_ceo}</TableCell>
                    <TableCell align="center">{row.address}</TableCell>
                    <TableCell align="center">{row.com_phone}</TableCell>
                    <TableCell align="center">
                      {row.meta
                        ? row.meta.home_page
                          ? row.meta.home_page
                          : ""
                        : ""}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          <TableRow>
            <ThemeProvider theme={theme}>
              <TablePagination
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
