import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { Auth } from "aws-amplify";
import "./AdminHeader.css";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";

const menu = ["記事管理", "アカウント" ,"タグ管理"];

const AdminHeader = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activeMenu, setActiveMenu] = useState(menu[0]);
  const cookies = new Cookies();

  useEffect(() => {
    const activeMenu = cookies.get("activeMenu");
    if (activeMenu) {
      setActiveMenu(activeMenu);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (data) => {
    setActiveMenu(data);
    cookies.set("activeMenu", data);
    if (data === menu[0]) {
      navigate("/admin/blogs");
    } else if (data === menu[1]) {
      navigate("/admin/account");
    }else if (data === menu[2]) {
      navigate("/admin/tags");
    }
    
    setAnchorElNav(null);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut({ global: true });
      localStorage.removeItem("@accessToken");
      localStorage.removeItem("@refreshToken");
      navigate("/admin/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <AppBar className="admin-header" position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            className="logo"
            variant="h6"
            noWrap
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            apotto
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuRoundedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menu.map((page) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            className="logo"
            variant="h5"
            noWrap
            href=""
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            apotto
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menu.map((page) => (
              <Button
                key={page}
                onClick={() => handleCloseNavMenu(page)}
                sx={{
                  mx: { sm: 4, lg: 8 },
                  display: "block",
                  fontSize: "20px",
                  fontWeight: "700",
                  borderRadius: "0px",
                  color: activeMenu === page ? "#000" : "#fff",
                  borderBottom: activeMenu === page ? "2px solid #000" : "",
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <div className="logout-btn" onClick={handleLogout}>
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6663 16.0416V18.9583L24.7913 18.9583V23.3333L32.083 17.4999L24.7913 11.6666L24.7913 16.0416L11.6663 16.0416Z"
                fill="black"
                fillOpacity="0.5"
              />
              <path
                d="M5.83333 30.625L18.9583 30.625C20.5669 30.625 21.875 29.3169 21.875 27.7083L21.875 21.875H18.9583L18.9583 27.7083L5.83333 27.7083L5.83333 7.29167L18.9583 7.29167L18.9583 13.125H21.875L21.875 7.29167C21.875 5.68312 20.5669 4.375 18.9583 4.375L5.83333 4.375C4.22479 4.375 2.91667 5.68312 2.91667 7.29167L2.91667 27.7083C2.91667 29.3169 4.22479 30.625 5.83333 30.625Z"
                fill="black"
                fillOpacity="0.5"
              />
            </svg>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AdminHeader;
