import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        background: "#00000018",
        height: "100%",
        display: "flex",
        padding: "4px 8px",
        margin: "0 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#000",
        borderRadius: "50px",
      }}
    >
      <Stack
        sx={{ display: "flex", alignItems: "center" }}
        direction="row"
        gap={1}
      >
        <Typography style={{ whiteSpace: "nowrap" }}>{data}</Typography>
        <Cancel
          sx={{
            cursor: "pointer",
            fontSize: "18px",
            color: "rgba(0, 0, 0, 0.3)",
          }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};

export default function InputTags({ parentCallback, tagList }) {
  const [tags, SetTags] = useState([]);
  const tagRef = useRef();

  useEffect(() => {
    SetTags(tagList === undefined || tagList === "" ? [] : tagList.split(","));
  }, [tagList]);

  const handleDelete = (value) => {
    const newTags = tags.filter((val) => val !== value);
    SetTags(newTags);
    parentCallback(newTags);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    SetTags([...tags, tagRef.current.value.trim()]);
    parentCallback([tags, tagRef.current.value.trim()]);
    tagRef.current.value = "";
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          fullWidth
          variant="outlined"
          size="small"
          sx={{ margin: "1rem 0" }}
          margin="none"
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  margin: "0 0.2rem 0 0",
                  display: "flex",
                }}
              >
                {tags.map((data, index) => {
                  return (
                    <Tags data={data} handleDelete={handleDelete} key={index} />
                  );
                })}
              </Box>
            ),
          }}
        />
      </form>
    </Box>
  );
}
