import * as React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ListTrendy from "./Pages/ListTrendy/ListTrendy";
import HomePage from "./Pages/HomePage/HomePage";
import TrendyDetail from "./Pages/TrendyDetail/TrendyDetail";
import AdminLayout from "./Layouts/AdminLayout/AdminLayout";
import UserLayout from "./Layouts/UserLayout/UserLayout";
import CreateTrendy from "./Pages/Admin/CreateTrendy/CreateTrendy";
import TrendyManage from "./Pages/Admin/TrendyManage/TrendyManage";
import VideoDetail from "./Pages/VideoDetail/VideoDetail";
import "./App.css";
import About from "./Pages/About/About";
import Login from "./Pages/Admin/Login/Login";
// import CompanyManage from "./Pages/Admin/CompanyManage/CompanyManage";
import Account from "./Pages/Admin/Account/Account";
import TagsManage from "./Pages/Admin/tags/Tags";
import awsmobile from "./aws-exports";
import {Amplify} from "aws-amplify"
import FavoritePage from "./Pages/Favorite/Favorite";
Amplify.configure(awsmobile);
function App() {
  const ProtectedRoute = () => {
    const user = localStorage.getItem("@accessToken");
    return user ? <Outlet /> : <Navigate to="/admin/login" />;
  };
  return (
    <div className="page">
      <Routes>
        <Route path="/" element={<UserLayout />}>
          <Route index element={<HomePage />}></Route>
          <Route path="list-trendy" element={<ListTrendy />}></Route>
          <Route path="favorite" element={<FavoritePage />}></Route>
          <Route path="detail/:id" element={<TrendyDetail />}></Route>
          <Route path="player" element={<VideoDetail />}></Route>
          <Route path="about/:id" element={<About />}></Route>
        </Route>
        <Route path="admin" element={<Login />}></Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="admin/create" element={<CreateTrendy />}></Route>
            <Route path="admin/edit/:id" element={<CreateTrendy />}></Route>
            <Route path="admin/blogs" element={<TrendyManage />}></Route>
            <Route path="admin/tags" element={<TagsManage />}></Route>
            {/* <Route path="admin/company" element={<CompanyManage />}></Route> */}
            <Route path="admin/account" element={<Account />}></Route>
          </Route>
        </Route>

        <Route path="admin/login" element={<Login />}></Route>
      </Routes>
    </div>
  );
}

export default App;
