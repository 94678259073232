/* eslint-disable react-hooks/exhaustive-deps */
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import "./TagList.css";
import Cookies from "universal-cookie";

function TagList({ isEdit, tagList, parentCallback }) {
  const [tags, setTags] = useState([]);
  const [isClickTag, setIsClickTag] = useState(true);
  const cookies = new Cookies();
  useEffect(() => {
    setTags(tags);
  }, [isClickTag]);
  useEffect(() => {
    let allTag = "";
    for (let i = 0; i < tagList.length; i++) {
      allTag += "," + tagList[i].tag;
    }
    allTag = [...new Set(allTag.split(","))];
    allTag.shift();
    for (let i = 0; i < allTag.length; i++) {
      if (
        cookies.get("selectedTag") !== undefined &&
        cookies.get("selectedTag").includes(allTag[i])
      ) {
        allTag[i] = { name: allTag[i], isActive: true };
      } else {
        allTag[i] = { name: allTag[i], isActive: false };
      }
    }
    if(isEdit === true){
      allTag.unshift({
      name: "すべて",
      isActive:
        cookies.get("selectedTag") === undefined ||
        cookies.get("selectedTag") === "すべて",
      });
    }
    
    if (isEdit === false) {
      cookies.remove("selectedTag", { path: "/" });
    }
    setTags(allTag);
  }, [tagList]);
  const handleClickTag = (data) => {
    if (isEdit === true && data !== 0) {
      tags[0].isActive = false;
      tags[data].isActive = !tags[data].isActive;
      if (tags.filter((tag, index) => tag.isActive === true).length === 0) {
        tags[0].isActive = true;
      }
      setIsClickTag(!isClickTag);
      parentCallback(tags.filter((tag) => tag.isActive === true));
    } else if (isEdit === true && data === 0 && tags[data].isActive === false) {
      tags[data].isActive = true;
      for (let i = 1; i < tags.length; i++) {
        if (tags[i].isActive === true) {
          tags[i].isActive = false;
        }
      }
      setIsClickTag(!isClickTag);
      parentCallback(tags.filter((tag) => tag.isActive === true));
    }
  };

  return (
    <Stack className="tag" direction="row" spacing={1}>
      {tags.map((tag, index) => {
        if (tag.isActive === true) {
          return (
            <Chip
              key={index}
              onClick={() => handleClickTag(index)}
              className="tag__text tag__text-active"
              label={tag.name}
            />
          );
        } else {
          return (
            <Chip
              key={index}
              onClick={() => handleClickTag(index)}
              className="tag__text"
              label={tag.name}
            />
          );
        }
      })}
    </Stack>
  );
}

export default TagList;
