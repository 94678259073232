import axios from "axios";

const instance = axios.create({
  baseURL:
    "https://qbeutrus71.execute-api.ap-northeast-1.amazonaws.com/devpionero/",
    // "http://172.16.1.25:8088/",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("@accessToken")}`,
  },
});

export default instance;
