import { Outlet } from "react-router-dom";
import UserHeader from "../../Components/UserHeader/UserHeader";
import "./UserLayout.css";

function UserLayout() {
  return (
    <div className="user-layout">
      <UserHeader />
      <Outlet />
    </div>
  );
}

export default UserLayout;
