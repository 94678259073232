import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "./UserHeader.css";
import Cookies from "universal-cookie";
import {Auth} from "aws-amplify";
import axios from "../../config/axios";
import { Popover, Typography } from "@mui/material";
import { useEffect } from "react";


function UserHeader() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLogin, setAnchorElLogin] = React.useState(null);
  const [auth , setAuth] = React.useState(true);
  const open = Boolean(anchorEl);
  const openLogin = Boolean(anchorElLogin);
  const id = openLogin ? 'simple-popover' : undefined;

  useEffect(()=>{
    Auth.currentAuthenticatedUser().then(user =>{
      setAuth(true);
    }).catch(e=>{
      setAuth(false)
    });
  },[]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickLogin = (event) => {
    setAnchorElLogin(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    setAnchorElLogin(null);
  };
  const handleClickHome = () => {
    cookies.remove("selectedTag", { path: "/" });
    navigate("/");
    window.location.reload();
  };
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const signIn = () =>{
    Auth.federatedSignIn({ provider : "Google"}).then(user => {axios.get(`user_login?userId=${user.username}&email=${user.attributes.email}`)})
  }

  return (
    <div>
      <header>
        <div className="header">
          <Button
            id="basic-button"
            className="header__sandwich"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuRoundedIcon></MenuRoundedIcon>
          </Button>
          <Link className="header__text-logo" to="/" onClick={handleClickHome}>
            apotto
          </Link>
          <div className="item-right">
            <div onClick={() => openInNewTab('https://www.apotto.jp/portal')}><img src="/svg/create.svg" alt="create" className="img-create"/></div>
            <div aria-describedby={id} variant="contained" onClick={handleClickLogin} ><img src="/user.jpeg" alt="user" className="img-user"/></div>
            <Popover
              id={id}
              open={openLogin}
              anchorEl={anchorElLogin}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 1 }} className="Typography">
                {auth&&(<><div onClick={() => Auth.signOut()}>ログアウトする</div></>)}
                {!auth&&(<><div onClick={signIn}>ログインする</div></>)}
              </Typography>
            </Popover>
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Link className="menu-link" to="/" onClick={handleClickHome}>
            <MenuItem onClick={handleClose}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.6273 12.2234L12.7228 0.682818C12.6443 0.5995 12.5511 0.533399 12.4484 0.488298C12.3457 0.443198 12.2357 0.419983 12.1245 0.419983C12.0134 0.419983 11.9033 0.443198 11.8007 0.488298C11.698 0.533399 11.6047 0.5995 11.5262 0.682818L0.621728 12.2234C0.304044 12.5598 0.124023 13.0168 0.124023 13.4935C0.124023 14.4832 0.883817 15.2879 1.81834 15.2879H2.96729V23.5228C2.96729 24.019 3.34586 24.42 3.81445 24.42H10.4302V18.1394H13.3953V24.42H20.4346C20.9032 24.42 21.2817 24.019 21.2817 23.5228V15.2879H22.4307C22.8808 15.2879 23.3123 15.1001 23.63 14.7608C24.2892 14.0599 24.2892 12.9243 23.6273 12.2234Z"
                  fill="black"
                />
              </svg>
              <b className="menu-text">ホーム</b>
            </MenuItem>
          </Link>
          <Link className="menu-link" to="/list-trendy">
            <MenuItem onClick={handleClose}>
              <svg
                width="25"
                height="25"
                viewBox="0 0 27 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 19.5H15C16.0609 19.5 17.0783 19.0786 17.8284 18.3284C18.5786 17.5783 19 16.5609 19 15.5V3.5C19 2.96957 18.7893 2.46086 18.4142 2.08579C18.0391 1.71071 17.5304 1.5 17 1.5H7C6.46957 1.5 5.96086 1.71071 5.58579 2.08579C5.21071 2.46086 5 2.96957 5 3.5V16.5C5 18.157 4.657 19.5 3 19.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 8.5C1 7.96957 1.21071 7.46086 1.58579 7.08579C1.96086 6.71071 2.46957 6.5 3 6.5H5V17C5 18.38 4.38 19.5 3 19.5C1.62 19.5 1 18.38 1 17V8.5Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 7.5C10.5523 7.5 11 7.05228 11 6.5C11 5.94772 10.5523 5.5 10 5.5C9.44772 5.5 9 5.94772 9 6.5C9 7.05228 9.44772 7.5 10 7.5Z" stroke="black" stroke-width="2" stroke-linecap="round"/>
                <path d="M9 12.5H15M9 15.5H12" stroke="black" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <b className="menu-text">特集一覧</b>
            </MenuItem>
          </Link>
          <Link className="menu-link" to="/favorite">
            <MenuItem onClick={handleClose}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.779 23.3801C4.65225 24.0904 5.36399 24.6456 5.99123 24.3288L13.1248 20.7195L20.2568 24.3288C20.8841 24.6456 21.5958 24.0904 21.4691 23.3817L20.1203 15.8142L25.8435 10.445C26.3797 9.94269 26.1035 9.02436 25.3852 8.92517L17.4261 7.81165L13.8772 0.888977C13.8096 0.74865 13.7029 0.63009 13.5696 0.547071C13.4363 0.464052 13.2818 0.419983 13.124 0.419983C12.9663 0.419983 12.8118 0.464052 12.6785 0.547071C12.5451 0.63009 12.4385 0.74865 12.3709 0.888977L8.82193 7.81325L0.862822 8.92677C0.14621 9.02596 -0.13166 9.94429 0.402955 10.4466L6.12773 15.8158L4.779 23.3833V23.3801ZM12.7495 18.9532L6.75984 21.9834L7.88757 15.6527C7.91399 15.5069 7.90365 15.357 7.85747 15.2162C7.81128 15.0753 7.73067 14.9477 7.6227 14.8447L2.90053 10.4131L9.48491 9.49152C9.62126 9.47125 9.75058 9.41874 9.8618 9.3385C9.97302 9.25825 10.0628 9.15265 10.1235 9.03076L13.1216 3.1784L16.1229 9.03076C16.1836 9.15265 16.2734 9.25825 16.3846 9.3385C16.4959 9.41874 16.6252 9.47125 16.7615 9.49152L23.3459 10.4115L18.6237 14.8431C18.5155 14.9463 18.4348 15.0741 18.3886 15.2153C18.3424 15.3565 18.3322 15.5067 18.3589 15.6527L19.4866 21.9834L13.497 18.9532C13.3811 18.8944 13.2527 18.8637 13.1224 18.8637C12.9921 18.8637 12.8637 18.8944 12.7479 18.9532H12.7495Z"
                  fill="black"
                />
              </svg>
              <b className="menu-text">お気に入り</b>
            </MenuItem>
          </Link>
        </Menu>
      </header>
    </div>
  );
}

export default UserHeader;
