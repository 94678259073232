/* eslint-disable react-hooks/exhaustive-deps */
import TagList from "../../Components/TagList/TagList";
import VideoList from "../../Components/VideoList/VideoList";
import InputSearch from "../../Components/InputSearch/InputSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

import axios from "../../config/axios";
import Pagination from "@mui/material/Pagination";
import Cookies from "universal-cookie";
import "./HomePage.css";
import { Auth } from "aws-amplify";

function HomePage() {
  var [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [auth , setAuth] = useState(false);
  const [favorite , setFavorite] = useState([]);
  console.log("🚀 ~ file: HomePage.js:30 ~ HomePage ~ favorite", favorite)
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("title");
  useEffect(()=>{
    if (searchText === null) {
      getAllVideos();
    } else {
      setOpen(true);
      cookies.remove("selectedTag", { path: "/" });
      getSearchVideos();
    }
    (()=>{
        Auth.currentAuthenticatedUser().then( user =>{
          axios.get(`user_login?userId=${user.username}&email=${user.attributes.email}`)
          console.log("🚀 ~ file: HomePage.js ~ line 43 ~ Auth.currentAuthenticatedUser ~ user", user)
          axios.get(`favorite?userId=${user.username}`).then(favorite=>{
            setFavorite(favorite.data.data.Item.videoFavorite)
          }).catch(()=>{setFavorite([])})
          setAuth(true)
        }).catch(e=>{
          setAuth(false)
        })
    }) ()
   
  },[searchText,page])

  const cookies = new Cookies();
  const handleClickVideo = (data) => {
    navigate(`/player?v=${data}&skip=true`);
  };
  const handleClickMaker = (data) => {
    navigate(`/about/${data}`);
  };

  async function getAllVideos() {
    let res = [];
    setOpen(true);
    if (
      cookies.get("selectedTag") === undefined ||
      cookies.get("selectedTag") === "すべて"
    ) {
      res = await axios.get(`videos?page=${page}`);
    } else {
      res = await axios.get(
        `videos?tags=${cookies.get("selectedTag")}&page=${page}`
      );
    }
    if (res.status === 200) {
      const allTags = await axios.get("publictags");
      let tags = allTags.data.data.filter((value) => value.tag !== undefined);
      setTags(tags);
      setVideos(res.data.data);
      setTotalPage(res.data.total);
      setOpen(false);

    }
  }

  async function getSearchVideos() {
    const res = await axios.get(`videos?page=${page}&title=${searchText}`);
    if (res.status === 200) {
      const allTags = await axios.get("publictags");
      const tags = allTags.data.data.filter(
        (value) => value.tag !== undefined
      );
      setTags(tags);
      setTotalPage(res.data.total);
      setOpen(false);

      setVideos(res.data.data);
    }
  }

  const handleChangePage = (event, value) => {
    if (value !== page) {
      setOpen(true);
      setPage(value);
      window.scrollTo(0, 0);
    }
  };

  const getTagSelected = async (selectedTag) => {
    navigate("/");
    setOpen(true);
    setPage(1);
    let listSelectedTag = "";
    for (let i = 0; i < selectedTag.length; i++) {
      if (listSelectedTag === "") {
        listSelectedTag = listSelectedTag + selectedTag[i].name;
      } else {
        listSelectedTag = listSelectedTag + "," + selectedTag[i].name;
      }
    }
    var res = [];
    if (listSelectedTag === "" || listSelectedTag === "すべて") {
      cookies.set("selectedTag", "すべて");
      res = await axios.get(`videos?page=1`);
    } else {
      cookies.set("selectedTag", listSelectedTag);
      res = await axios.get(`videos?page=1&tags=${listSelectedTag}`);
    }
    if (res.status === 200) {
      setTotalPage(res.data.total);
      setOpen(false);
      setVideos(res.data.data);
    }
  };

  return (
    <div className="home-page">
      <InputSearch></InputSearch>
      <Divider className="divider" />
      <TagList isEdit={true} tagList={tags} parentCallback={getTagSelected} />
      <Divider className="divider" />
      <Grid container spacing={2}>
      {/* return  */}

        {videos.map((video) => {
          if(favorite.length){
            const favoriteItem = favorite.find(favorite => favorite === video.id)
            video.S = favoriteItem 
            ? favoriteItem
            : null
            return video 
          }else{
            return video 
          }
        }).map(
          (item)=><VideoList video={item} handleClickMaker={handleClickMaker} handleClickVideo ={handleClickVideo} auth={auth} page={'home'}/>
        )}
      </Grid>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <Stack>
          <Pagination
            count={totalPage}
            size="medium"
            onChange={handleChangePage}
            boundaryCount={0}
            color="primary"
            page={page}
          />
        </Stack>
      </div> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default HomePage;
