import InputSearch from "../../Components/InputSearch/InputSearch";
import Trendy from "../../Components/Trendy/Trendy";
import { useState, useEffect } from "react";
import axios from "../../config/axios";
import { useNavigate } from "react-router-dom";
import "./ListTrendy.css";
import { Grid } from "@mui/material";
function ListTrendy() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios.get("admin/blogs").then((res) => {
      setBlogs(res.data.data);
    });
  }, []);

  const handleClickBlog = (blogId) => {
    navigate(`/detail/${blogId}`);
  };

  return (
    <div>
      <InputSearch></InputSearch>
      <div className="list-trendy__title">特集一覧</div>
      <Grid container spacing={2}>
        {blogs.map((blog, index) => {
          if (blog.status === "public") {
            return (
              <Grid item xs={12} md={6}>
                <div key={index} onClick={() => handleClickBlog(blog.id)}>
                  <Trendy blog={blog} />
                </div>
              </Grid>
            );
          } else {
            return "";
          }
        })}
      </Grid>
    </div>
  );
}

export default ListTrendy;
