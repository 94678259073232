import VideoList from "../../Components/VideoList/VideoList";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";

import axios from "../../config/axios";
import Cookies from "universal-cookie";
import "./Favorite.css";
import { Auth } from "aws-amplify";

function FavoritePage() {
  var [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [open, setOpen] = useState(false);
  const [auth , setAuth] = useState(false);
  const [favorite , setFavorite] = useState([]);
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("title");
  useEffect(()=>{
    setPage(1);
    getAllVideos();
    (()=>{
        Auth.currentAuthenticatedUser().then( user =>{
          axios.get(`user_login?userId=${user.username}&email=${user.attributes.email}`)
          axios.get(`favorite?userId=${user.username}`).then(favorite=>{
            setFavorite(favorite.data.data.Item.videoFavorite)
          }).catch(()=>{setFavorite([])})
          setAuth(true)
        }).catch(e=>{
          setAuth(false)
        })
    }) ()
   
  },[searchText,page])

  const cookies = new Cookies();
  const handleClickVideo = (data) => {
    navigate(`/player?v=${data}&skip=true`);
  };
  const handleClickMaker = (data) => {
    navigate(`/about/${data}`);
  };
  
  async function getAllVideos() {
    let res = [];
    setOpen(true);
    if (
      cookies.get("selectedTag") === undefined ||
      cookies.get("selectedTag") === "すべて"
    ) {
      res = await axios.get(`videos?page=${page}`);
    } else {
      res = await axios.get(
        `videos?tags=${cookies.get("selectedTag")}&page=${page}`
      );
    }
    if (res.status === 200) {
      setVideos(res.data.data);
      setOpen(false);

    }
  }
  
  return (
    <div className="home-page">
      <div className="list-trendy__title">お気に入り一覧</div>
      
      <Grid container spacing={2}>
      {/* return  */}
        {videos.map((video) => {
          if(favorite.length !== 0){
            const favoriteItem = favorite.find(favorite => favorite === video.id)
            video.S = favoriteItem 
            ? favoriteItem
            : null
            return video 
          }else{
            return video 
          }
        }).map(
          (item)=><VideoList video={item} handleClickMaker={handleClickMaker} handleClickVideo ={handleClickVideo} auth={auth} page={'favorite'}/>
        )}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default FavoritePage;
