import "./Trendy.css";
import { Storage, Amplify } from "aws-amplify";
import awsmobile from "../../aws-exports";
import { useState } from "react";
import { useEffect } from "react";

function Trendy({ blog }) {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [summary, setSummary] = useState("");
  Amplify.configure({
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:bbe4b373-416f-4bb4-951e-63b5bde2b2d6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Ga0inlK7E",
    "aws_user_pools_web_client_id": "3lgf1laq1qmortv99g7gv4nsmu",
    "oauth": {
        "domain": "apottoxinc752db7cf-752db7cf-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "apottoxincauth://",
        "redirectSignOut": "apottoxincauth://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],    
    "aws_user_files_s3_bucket": "apottoxincdevs3bucket155457-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
    });
  const regexImage = /<img[^>]+src="([^">]+)"/g;
  const imageUrl = regexImage.exec(blog.description);
  const styles = {
    paperContainer: {
      backgroundSize: "cover",
      backgroundImage: `url(${imageUrl === null ? "" : backgroundImage})`,
    },
  };

  useEffect(() => {
    async function getImage() {
      if (imageUrl !== null) {
        const url = await Storage.get(imageUrl[1]);
        setBackgroundImage(url);
      }
    }
    getImage();
    const regexParagraph = new RegExp("<p>(.*?)</p>", "g");
    let summary = blog.description.match(regexParagraph);
    if (summary !== null) {
      summary = summary.find((sum) => sum.length > 10);
      setSummary(summary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="trendy">
      <div className="trendy__image" style={styles.paperContainer}></div>
      <div className="trendy__content">
        <h2 className="trendy__title">{blog.title}</h2>
        {/* <div className="trendy__time">9月限定開催。</div> */}
        <div
          className="trendy__summary"
          dangerouslySetInnerHTML={{ __html: summary }}
        ></div>
        <div className="trendy-list-tag__footer">
          {blog.tags !== undefined && blog.tags !== ""
            ? blog.tags.split(",").map((tag, index) => {
                return (
                  <span key={index} className="trendy-list-tag__footer-tag">
                    #{tag}
                  </span>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
}

export default Trendy;
