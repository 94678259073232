/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "pottoinc-20221021102050-hostingbucket-devpionero",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d16icq9zz2kjdl.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-northeast-1:7301ae7f-9e2f-45b8-8cbd-21ee49624889",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_kbNEwWAAm",
    "aws_user_pools_web_client_id": "k5ga96c59p58ogltu8of1oag9",
    "oauth": {
        "domain": "apottoxinc92ee0047-92ee0047-devpionero.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://portal.apotto.jp/",
        "redirectSignOut": "https://portal.apotto.jp/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "apipionero",
            "endpoint": "https://qbeutrus71.execute-api.ap-northeast-1.amazonaws.com/devpionero",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "apottoxincdevs3bucket155457-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
