import InputSearch from "../../Components/InputSearch/InputSearch";
import { Box, Tab, Tabs, Avatar, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../config/axios";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import "./About.css";
import { format } from "date-fns";

function About() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [company, setCompany] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    axios.get(`companie/show/${id}`).then((response) => {
      setCompany(response.data.data);
    });
    axios.get(`videos/filter?makerID=${id}`).then((response) => {
      setVideos(response.data.data);
    });
  }, [id]);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const handleClickVideo = (data) => {
    navigate(`/player?v=${data.S}&skip=true`);
  };
  return (
    <div>
      <InputSearch />
      <div className="about__company">
        <Avatar
          className="about__company-avatar"
          style={{ border: "1px solid #ccc" }}
          alt="avatar"
          src={company.logo}
          sx={{ width: 54, height: 54 }}
        ></Avatar>
        <div className="about__company-name">{company.com_name}</div>
      </div>
      <Box>
        <Tabs className="list-tabs" value={tabIndex} onChange={handleTabChange}>
          <Tab className="list-tabs__tab" label="ABOUT"></Tab>
          <Tab className="list-tabs__tab" label="360 Tour"></Tab>
        </Tabs>
      </Box>
      <Box sx={{ paddingTop: 2 }}>
        {tabIndex === 0 && (
          <Box className="about-tab">
            <div className="about-tab__company">
              <div className="about-tab__company-name">詳細</div>
              <Grid
                container
                spacing={2}
                style={{ maxWidth: "600px", margin: "auto" }}
              >
                <Grid item className="about-tab__info-label" xs={3}>
                  代表名
                </Grid>
                <Grid className="about-tab__info-label" item xs={1}>
                  :
                </Grid>
                <Grid item xs={8}>
                  {company.com_ceo}
                </Grid>

                <Grid item className="about-tab__info-label" xs={3}>
                  住所
                </Grid>
                <Grid className="about-tab__info-label" item xs={1}>
                  :
                </Grid>
                <Grid item xs={8}>
                  {company.address}
                </Grid>

                <Grid item className="about-tab__info-label" xs={3}>
                  電話番号
                </Grid>
                <Grid className="about-tab__info-label" item xs={1}>
                  :
                </Grid>
                <Grid item xs={8}>
                  {company.com_phone}
                </Grid>

                <Grid item className="about-tab__info-label" xs={3}>
                  ホームページ
                </Grid>
                <Grid className="about-tab__info-label" item xs={1}>
                  :
                </Grid>
                <Grid item xs={8}>
                  {company.meta
                    ? company.meta.home_page
                      ? company.meta.home_page
                      : ""
                    : ""}
                </Grid>
              </Grid>
              {company.com_photo ? (
                <img
                  style={{ width: "100%", marginTop: "30px" }}
                  src={company.com_photo}
                  alt="company"
                />
              ) : (
                ""
              )}
            </div>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box className="video-tab">
            <Grid container spacing={2}>
              {videos.map((video, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <div
                    onClick={() => handleClickVideo(video.id)}
                    className="video-tab__video"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div
                          style={{
                            backgroundColor: "#ccc",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `url(${
                              video.meta
                                ? video.meta.M.outdoor
                                  ? video.meta.M.outdoor.S
                                  : ""
                                : ""
                            })`,
                          }}
                          className="video-tab__video-iframe"
                        >
                          <PlayCircleFilledWhiteIcon className="video-tab__play-icon" />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="video-tab__video-info">
                          <div className="video-info__title">
                            {video.title.S}
                          </div>
                          <div className="video-info__sub-title">
                            {format(
                              new Date(Date.parse(video.createdAt.S)),
                              "yyyy/MM/dd"
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default About;
