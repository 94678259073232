import axios from "../../../config/axios";
import { useEffect, useState } from "react";
import "./Tags.css";


function TagsManage() {
  const [tags, setTags] = useState([]);
  const [publictags, setPublicTags] = useState([]);
  async function getallTag(){
    const allTags = await axios.get("tags");
    const tags = allTags.data.data.filter((value) => value.tags !== undefined);
    setTags(tags.map(tag=>( tag.tags)))
 
    const publicTags = await axios.get("publictags");
    const publictags = publicTags.data.data.filter((value) => value.tag !== undefined);
   
    setPublicTags(publictags.map(tag=>( tag.tag)))
    
  }
  useEffect(() => {
    getallTag();
  },[]);

  const onPublicTag = (tags) => {
    if(!publictags.includes(tags)){
      setPublicTags(publictags => [...publictags,tags])
      axios.get(`/create-publictag/${tags}`)
    }
  }

  const onCloseTag = (tags) => {
    const index = publictags.indexOf(tags);
    publictags.splice(index,1)
    setPublicTags(publictags => [...publictags])
    axios.get(`/update-publictag/${tags}`)
  }

  

  return (
    <div className="content">
      <h2>タグ一覧</h2>
      <h4>表示されたいタグを選択してください</h4>
      <div className="content-tags">
        {tags.map((tag)=> 
        <div className = "tags" onClick={()=>onPublicTag(tag)} key={tag}>
        {tag}
        </div>
        )}
      </div>
      <h2 className="title-tag">表示タグ</h2>
      <hr></hr>
      <div className="content-tags">
      {publictags.map((tag)=> 
        <div className = "tags" key={tag} onClick={()=>onCloseTag(tag)}>
        {tag} <img src="/svg/close.svg" alt="close" ></img>
        </div>
        )}
      </div>
    </div>
  );
}

export default TagsManage;
