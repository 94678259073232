import { useNavigate, useParams } from "react-router-dom";
import axios from "../../config/axios";
import { useEffect, useState } from "react";
import "./TrendyDetail.css";
import { Backdrop, CircularProgress, Divider, Grid } from "@mui/material";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import Avatar from "@mui/material/Avatar";
import { Storage, Amplify } from "aws-amplify";
import InputBase from "@mui/material/InputBase";
import { format } from "date-fns";

function TrendyDetail() {
  Amplify.configure(
    {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:bbe4b373-416f-4bb4-951e-63b5bde2b2d6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Ga0inlK7E",
    "aws_user_pools_web_client_id": "3lgf1laq1qmortv99g7gv4nsmu",
    "oauth": {
        "domain": "apottoxinc752db7cf-752db7cf-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "apottoxincauth://",
        "redirectSignOut": "apottoxincauth://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "apottoxincdevs3bucket155457-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"    
    }
  );
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const [blog, setBlog] = useState([]);
  const [videosByTag, setVideosByTag] = useState([]);
  
  const [openLoading, setOpenLoading] = useState(false);
  const [tags , setTags] = useState({});
  let { id } = useParams();
  const handleClickVideo = (data) => {
    navigate(`/player?v=${data}&skip=true`);
  };
  const handleClickMaker = (data) => {
    navigate(`/about/${data}`);
  };
  const SearchBy = async (event) => { 
    if (event.key === "Enter") {
      event.preventDefault();
      const result = await axios.get(
        `videos-by-tags?tags=${tags}&search=${event.target.value}`
      );
      if (result.status === 200) {
        if(result.data.data.length !== 0){
          setVideosByTag(result.data.data);
        }else{
          setVideosByTag([]);
        }
      }
    }  
  }

  useEffect(() => {
    async function getBlogDetail() {
      setOpenLoading(true);
      const res = await axios.get(`/blog/${id}`);
      const regexImage = /<img[^>]+src="([^">]+)"/g;
      const urls = res.data.data.description.match(regexImage);
      if (urls !== null) {
        for (let i = 0; i < urls.length; i++) {
          const key = regexImage.exec(urls[i]);
          regexImage.lastIndex = 0;
          const url = await Storage.get(key[1]);
          res.data.data.description = res.data.data.description.replace(
            key[1],
            url
          );
        }
      }
      setBlog(res.data.data);
      setTags(res.data.data.tags)
      if (res.data.data.tags !== "") {
        const result = await axios.get(
          `videos-by-tags?tags=${res.data.data.tags}`
        );
        if (result.status === 200) {
          setVideosByTag(result.data.data);
        }
      }
      setOpenLoading(false);
    }

    getBlogDetail();
  }, [id]);
  return (
    <div className="detail">
      {/* <span onClick={handleBack} className="detail__back-btn">
        <span className="back-btn__text">
          <svg
            width="7"
            height="17"
            viewBox="0 0 7 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.31303 0.0853767L0 8.07928L5.31303 16.0732L6.2561 14.6543L1.88613 8.07928L6.2561 1.50429L5.31303 0.0853767Z"
              fill="black"
            />
          </svg>
          <span className="back-btn__content">戻る</span>
        </span>
      </span> */}
      <div className="detail__content">
        <div className="content__title">{blog.title}</div>
        <div
          className="content__description ck-content"
          dangerouslySetInnerHTML={{ __html: blog.description }}
        ></div>
      </div>
      <div>
        <Divider className="divider" />
        <div className="list-tag__footer">
          {blog.tags !== undefined && blog.tags !== ""
            ? blog.tags.split(",").map((tag, index) => {
                return (
                  <span key={index} className="list-tag__footer-tag">
                    #{tag}
                  </span>
                );
              })
            : ""}
        </div>
      </div>
      <div className="input-search">
        <InputBase
        className="search__input"
        placeholder="Search"
        onKeyDown={SearchBy}
        />
      </div>
      {videosByTag.length===0&&<h3 className="notify">見つかりませんでした。</h3>}
      <Grid container spacing={2}>
        {videosByTag.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div key={index} className="list-video">
              <div
                className="list-video__video"
                onClick={() => handleClickVideo(video.id.S)}
              >
                <div
                  className="list-video__video-iframe"
                  style={{
                    backgroundColor: "#ccc",
                    backgroundImage: `url(${
                      video.meta
                        ? video.meta.M.outdoor
                          ? video.meta.M.outdoor.S
                          : ""
                        : ""
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  <PlayCircleFilledWhiteIcon className="list-video__play-icon" />
                </div>
              </div>
              <div className="list-video__title">
                <Avatar
                  onClick={() => handleClickMaker(video.makerID.S)}
                  className="list-video__title-avatar"
                  alt="avatar"
                  sx={{ width: 46, height: 46 }}
                  src={video.logo.S}
                ></Avatar>
                <div
                  className="list-video__title-text"
                  onClick={() => handleClickVideo(video.id.S)}
                >
                  <div className="title__main">{video.title.S}</div>
                </div>
              </div>
              <div className="title__sub">
                {format(new Date(Date.parse(video.createdAt.S)), "yyyy/MM/dd")}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default TrendyDetail;
