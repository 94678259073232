import "./Login.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { Auth, Amplify } from "aws-amplify";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";

function Login() {
  Amplify.configure(
    {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:bbe4b373-416f-4bb4-951e-63b5bde2b2d6",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_Ga0inlK7E",
    "aws_user_pools_web_client_id": "3lgf1laq1qmortv99g7gv4nsmu",
    "oauth": {
        "domain": "apottoxinc752db7cf-752db7cf-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "apottoxincauth://",
        "redirectSignOut": "apottoxincauth://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],    
    }
  );
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [username, setUserName] = useState(localStorage.getItem("@email"));
  const [password, setPassword] = useState("");
  const [usernameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isRemember, setIsRemember] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("@accessToken");
    if (accessToken) {
      navigate("/admin/blogs");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const signIn = async (event) => {
    try {
      setUserName(username.trim());
      setPassword(password.trim());
      if (username === "") {
        setUserNameError(true);
        return;
      } else {
        setUserNameError(false);
      }
      if (password === "") {
        setPasswordError(true);
        return;
      } else {
        setPasswordError(false);
      }
      setOpen(true);
      const user = await Auth.signIn(username, password);
      if (user.signInUserSession.refreshToken.token) {
        localStorage.setItem(
          "@refreshToken",
          user.signInUserSession.refreshToken.token
        );
        localStorage.setItem(
          "@accessToken",
          user.signInUserSession.accessToken.jwtToken
        );
        localStorage.setItem("@username", user.username);
        localStorage.setItem("@makerID", user.attributes["custom:makerID"]);
        if (isRemember === true) {
          localStorage.setItem("@email", username);
        } else {
          localStorage.removeItem("@email");
        }
        cookies.remove("activeMenu", { path: "/admin" });
        setOpen(false);
        navigate("/admin/blogs");
      }
    } catch (error) {
      setOpen(false);
      toast(error.message);
    }
  };

  const handleChangeUsername = (event) => {
    setUserName(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onCheck = async (event, data) => {
    await setIsRemember(data);
  };

  return (
    <div>
      <AppBar className="admin-header" position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters></Toolbar>
        </Container>
      </AppBar>
      <div className="login-page">
        <div>
          <img
            className="login-page__logo"
            src="/apotto_logo.png"
            alt="logo"
          ></img>
        </div>
        <form className="form-create">
          <label>Email</label>
          <TextField
            error={usernameError}
            helperText={usernameError ? "*必須項目" : ""}
            className="form-create__text-field"
            name="username"
            value={username}
            onChange={handleChangeUsername}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ width: "100%" }}
            variant="outlined"
          />
          <label>Password</label>
          <TextField
            error={passwordError}
            helperText={passwordError ? "*必須項目" : ""}
            className="form-create__text-field"
            name="password"
            type="password"
            onChange={handleChangePassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            sx={{ width: "100%" }}
            variant="outlined"
          />
          <FormControlLabel
            className="login-page__checkbox"
            control={
              <Checkbox
                style={{ color: "#26B6E3" }}
                onChange={onCheck}
                defaultChecked
              />
            }
            label="Remember"
          />
          <Button onClick={signIn} className="btn-login" variant="contained">
            Sign in
          </Button>
        </form>
      </div>
      <Toaster
        toastOptions={{
          style: {
            borderBottom: "3px solid red",
          },
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default Login;
