import "./Account.css";
import Grid from "@mui/material/Grid";
import { Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

function Account() {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);

  const handleChangeOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const handleChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const changePassword = () => {
    setOldPassword(oldPassword.trim());
    setNewPassword(newPassword.trim());
    if (oldPassword === "") {
      setOldPasswordError(true);
      return;
    } else {
      setOldPasswordError(false);
    }
    if (newPassword === "") {
      setNewPasswordError(true);
      return;
    } else {
      setNewPasswordError(false);
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const result = Auth.changePassword(user, oldPassword, newPassword);
        return result;
      })
      .then((data) => {
        navigate("/admin/login");
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <div className="account">
      <div className="account-form">
        <Grid className="account-form__item" container spacing={2}>
          <Grid item xs={4}>
            古いパスワード
          </Grid>
          <Grid item xs={1}>
            :
          </Grid>
          <Grid item xs={7}>
            <TextField
              error={oldPasswordError}
              helperText={oldPasswordError ? "*必須項目" : ""}
              className="account-form__text-field"
              size="small"
              onChange={handleChangeOldPassword}
              type="password"
              sx={{ width: "100%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BorderColorRoundedIcon className="account-form__icon-edit" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            新しいパスワード
          </Grid>
          <Grid item xs={1}>
            :
          </Grid>
          <Grid item xs={7}>
            <TextField
              error={newPasswordError}
              helperText={newPasswordError ? "*必須項目" : ""}
              className="account-form__text-field"
              size="small"
              type="password"
              onChange={handleChangeNewPassword}
              sx={{ width: "100%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BorderColorRoundedIcon className="account-form__icon-edit" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Button
          onClick={changePassword}
          className="account-form__btn"
          variant="contained"
        >
          保存
        </Button>
      </div>
    </div>
  );
}

export default Account;