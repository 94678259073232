import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./InputSearch.css";

export default function InputSearch() {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const text = searchParams.get("title");
  useEffect(() => {
    if (text !== null) {
      setSearchText(text);
    } else {
      setSearchText("");
    }
  }, [text]);

  const handleClickSearchButton = () => {
    navigate({
      pathname: "/",
      search: createSearchParams({
        title: searchText.trim(),
      }).toString(),
    });
  };

  const onChangeInputSearch = (event) => {
    setSearchText(event.target.value);
  };

  const onEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClickSearchButton();
    }
  };

  return (
    <Paper component="form" className="search-form">
      <InputBase
        className="search-form__input"
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={onChangeInputSearch}
        onKeyDown={onEnterKey}
        value={searchText}
      />
      <IconButton
        onClick={handleClickSearchButton}
        type="button"
        className="search-icon"
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
