import Button from "@mui/material/Button";
import TableData from "../../../Components/TableData/TableData";
import { useNavigate } from "react-router-dom";
import axios from "../../../config/axios";
import { useEffect, useState } from "react";
import "./TrendyManage.css";

function TrendyManage() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios.get("admin/blogs").then((res) => {
      setBlogs(res.data.data);
    });
  }, []);
  const handleAddButton = () => {
    navigate("/admin/create");
  };

  return (
    <div className="content">
      <Button
        className="button-create"
        onClick={handleAddButton}
        variant="contained"
      >
        新規作成
      </Button>
      <TableData
        dataType="blog"
        columns={["No", "タイトル", "作成日", "状況", "編集"]}
        rows={blogs}
      />
    </div>
  );
}

export default TrendyManage;
